export interface InterfacePackageData {
    id: number,
    ts_created: string,
    name: string,
    price: number
}

export interface InterfacePackageCreate {
    name: string,
    price: number
}

export interface InterfacePackageEdit {
    id: number,
    name: string,
    price: number
}

export const InitialPackageCreate: InterfacePackageCreate = {
    name: "",
    price: 0
}

export const InitialPackageEdit: InterfacePackageEdit = {
    id: 0,
    name: "",
    price: 0,
}