import { Box, Button, Container, Typography } from "@mui/material";
import CustomerTable from "../../features/customer/components/CustomerTable";
import { Add } from "@mui/icons-material";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { InterfaceCustomerEdit } from "../../features/customer/data/customer.interface";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteCustomer, getCustomer } from "../../features/customer/data/customer.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import CustomerForm from "../../features/customer/components/CustomerForm";
import useAccess from "../../hooks/useAccess";

export default function CustomerPage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [dataSelected, setDataSelected] = useState<InterfaceCustomerEdit | undefined>(undefined)

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteCustomer(id))
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getCustomer())
                    } catch (error) { }
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                title="Tambah Pelanggan"
                onClose={() => setModalAdd(false)}
                component={<CustomerForm mode="ADD" onSubmit={() => setModalAdd(false)} />}
            />
            <CustomModal
                open={Boolean(dataSelected)}
                title="Edit Pelanggan"
                onClose={() => setDataSelected(undefined)}
                component={<CustomerForm mode="EDIT" data={dataSelected} onSubmit={() => setDataSelected(undefined)} />}
            />
            <br />
            <Typography variant="h3">Pelanggan</Typography>
            {access("CREATE") && (
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button startIcon={<Add />} onClick={() => setModalAdd(true)}>Tambah</Button>
                </Box>
            )}
            <br />
            <CustomerTable onEdit={access("EDIT") ? setDataSelected : undefined} onDelete={access("DELETE") ? handleDelete : undefined} />
        </Container>
    )
}