export interface InterfaceUserData {
    id: number,
    ts_created: string,
    username: string,
    password: string,
    role_id: number | null | null,
    role: { name: string }
}

export interface InterfaceUserCreate {
    username: string
    password: string,
    role_id: number | null
}

export interface InterfaceUserEdit {
    id: number,
    username: string,
    password: string,
    role_id: number | null
}

export const InitialUserCreate: InterfaceUserCreate = {
    username: "",
    password: "",
    role_id: null
}

export const InitialUserEdit: InterfaceUserEdit = {
    id: 0,
    username: "",
    password: "",
    role_id: null
}