export interface InterfaceCustomerData {
    id: number,
    ts_created: string,
    name: string,
    phone: string
}

export interface InterfaceCustomerCreate {
    name: string,
    phone: string
}

export interface InterfaceCustomerEdit {
    id: number,
    name: string,
    phone: string
}

export const InitialCustomerCreate: InterfaceCustomerCreate = {
    name: "",
    phone: ""
}

export const InitialCustomerEdit: InterfaceCustomerEdit = {
    id: 0,
    name: "",
    phone: "",
}