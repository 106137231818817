import { Box, Button, Card, CardContent, CardHeader, Checkbox, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { ChangeEvent, FormEvent, Fragment, useEffect, useState } from "react";
import { InitialRoleEdit, InterfaceRoleEdit } from "../data/role.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch, useSelector } from "react-redux";
import { createRole, editRole, getMenu, getRole } from "../data/role.api";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { RootState } from "../../../store";

export default function RoleForm(props: { mode: "ADD" | "EDIT", data?: InterfaceRoleEdit, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { menuList } = useSelector((state: RootState) => state.role)

    const [values, setValues] = useState<InterfaceRoleEdit>(InitialRoleEdit)

    useEffect(() => {
        dispatch(getMenu())
    }, [dispatch])

    useEffect(() => {
        if (props.data !== undefined) {
            setValues(props.data)
        }
    }, [props.data])

    const handleChangeValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        let value = e.target.value
        setValues(last => ({
            ...last,
            [name]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createRole(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editRole(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            setValues(InitialRoleEdit)
            dispatch(getRole())
            props.onSubmit()
        } catch (error) { }
    }

    const handleCheckAll = (checked: boolean, url: string) => {
        setValues(last => ({
            ...last,
            access: checked
                ? (
                    last.access.filter(access => access.url === url).length > 0
                        ? (
                            last.access.map(access => {
                                if (access.url === url) {
                                    return {
                                        ...access,
                                        access: menuList.filter(menu => menu.url === url)[0].access
                                    }
                                }
                                return access
                            })
                        )
                        : [...last.access, { url: url, access: menuList.filter(menu => menu.url === url)[0].access }]
                )
                : last.access.filter(access => access.url !== url)
        }))
    }

    const handleCheck = (checked: boolean, url: string, accessName: string) => {
        setValues(last => ({
            ...last,
            access: last.access.filter(access => access.url === url).length > 0
                ? (
                    (last.access.filter(access => access.url === url)[0].access.length === 1) && !checked
                        ? last.access.filter(access => access.url !== url)
                        : (
                            last.access.map(access => {
                                if (access.url === url) {
                                    return {
                                        ...access,
                                        access: checked ? [...access.access, accessName] : access.access.filter(a => a !== accessName)
                                    }
                                }
                                return access
                            })
                        )
                )
                : [...last.access, { url: url, access: [accessName] }]
        }))
    }

    const isChecked = (url: string, access?: string) => {
        const accessFiltered = values.access.filter(access => access.url === url)
        const isFoundInValues = accessFiltered.length > 0
        if (access) {
            return isFoundInValues && accessFiltered[0].access.includes(access)
        }
        else {
            return isFoundInValues && accessFiltered[0].access.length === menuList.filter(menu => menu.url === url)[0].access.length
        }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <FormText label="Nama" name="name" value={values.name} required onChange={handleChangeValues} />
                <Card variant="outlined">
                    <CardHeader subheader="Akses" />
                    <CardContent>
                        <Table>
                            <TableBody>
                                {menuList.map(menu => (
                                    <Fragment key={menu.url}>
                                        <TableRow>
                                            <TableCell sx={{ verticalAlign: "top", paddingTop: 3 }} rowSpan={menu.access.length + 1}>{menu.name}</TableCell>
                                            <TableCell><Checkbox checked={isChecked(menu.url)} onChange={(e) => handleCheckAll(e.target.checked, menu.url)} /></TableCell>
                                            <TableCell>ALL</TableCell>
                                        </TableRow>
                                        {menu.access.map(access => (
                                            <TableRow key={`${menu.url}:${access}`}>
                                                <TableCell><Checkbox checked={isChecked(menu.url, access)} onChange={(e) => handleCheck(e.target.checked, menu.url, access)} /></TableCell>
                                                <TableCell>{access}</TableCell>
                                            </TableRow>
                                        ))}
                                    </Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button type="submit">Simpan</Button>
                </Box>
            </form>
        </Box>
    )
}