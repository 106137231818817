import React from 'react'
import { Box, Button } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { InitialCustomerEdit, InterfaceCustomerEdit } from "../data/customer.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import { createCustomer, editCustomer, getCustomer } from "../data/customer.api";
import alertSuccess from "../../../shared/alert/alertSuccess";

export default function CustomerForm(props: { mode: "ADD" | "EDIT", data?: InterfaceCustomerEdit, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfaceCustomerEdit>(InitialCustomerEdit)

    useEffect(() => {
        if (props.data !== undefined) {
            setValues(props.data)
        }
    }, [props.data])

    const handleChangeValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        let value = ["price"].includes(name) ? parseInt(e.target.value) : e.target.value
        setValues(last => ({
            ...last,
            [name]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createCustomer(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editCustomer(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            setValues(InitialCustomerEdit)
            dispatch(getCustomer())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <FormText label="Nama" name="name" value={values.name} required onChange={handleChangeValues} />
                <FormText label="HP" name="phone" value={values.phone} required onChange={handleChangeValues} />
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button type="submit">Simpan</Button>
                </Box>
            </form>
        </Box>
    )
}