import { Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import { getTransactionByDate } from "../data/transaction.api";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceTransactionData } from "../data/transaction.interface";
import { Delete, Payment, Print } from "@mui/icons-material";
import moment from "moment";
import moneyParser from "../../../shared/moneyParser";
import FormDate from "../../../shared/form/FormDate";
import { setDateEnd, setDateStart } from "../data/transaction.reducer";

export default function TransactionTable(props: {
    onDelete?: (id: number) => void,
    onPay?: (data: InterfaceTransactionData) => void
}) {

    const dispatch = useDispatch<any>()

    const { dateStart, dateEnd, transactionList } = useSelector((state: RootState) => state.transaction)

    useEffect(() => {
        const now = moment()
        dispatch(setDateStart(now.clone().subtract(7, 'days').format("YYYY-MM-DD")))
        dispatch(setDateEnd(now.format("YYYY-MM-DD")))
    }, [dispatch])

    useEffect(() => {
        if (dateStart !== "" && dateEnd !== "") {
            dispatch(getTransactionByDate({ dateColumn: "date_book", dateStart, dateEnd }))
        }
    }, [dispatch, dateStart, dateEnd])

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <FormDate label="Tanggal mulai" value={dateStart} onChange={(e) => dispatch(setDateStart(e.target.value))} />
                </Grid>
                <Grid item xs={3}>
                    <FormDate label="Tanggal akhir" value={dateEnd} onChange={(e) => dispatch(setDateEnd(e.target.value))} />
                </Grid>
                <Grid item xs={6}></Grid>
            </Grid>
            <br />
            <CustomTable
                columns={[
                    {
                        name: "Nomor Transaksi",
                        key: "id"
                    },
                    {
                        name: "Tanggal Booking",
                        key: "date_book",
                        render: (date: InterfaceTransactionData) => (
                            <span>{moment(date.date_book).format("YYYY-MM-DD")}</span>
                        )
                    },
                    {
                        name: "Pelanggan",
                        key: "room_id",
                        render: (data: InterfaceTransactionData) => (
                            <span>{data.customer.name}</span>
                        )
                    },
                    {
                        name: "Total",
                        key: "id",
                        render: (data: InterfaceTransactionData) => (
                            <span>{moneyParser(data.package_list.reduce((total: number, pkg) => total + (pkg.price * pkg.quantity), 0))}</span>
                        )
                    },
                    {
                        name: "Sisa Hutang",
                        key: "id",
                        render: (data: InterfaceTransactionData) => (
                            <span>{moneyParser(data.package_list.reduce((total, d) => total + (d.price * d.quantity), 0) - data.pay_list.reduce((total, d) => total + d.value, 0))}</span>
                        )
                    }
                ]}
                dataList={transactionList}
                actionList={(data: InterfaceTransactionData) => {
                    return (
                        <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                            {(props.onPay && data.total_paid < data.total) && (<Button startIcon={<Payment />} color="primary" onClick={() => props.onPay && props.onPay(data)}>Bayar</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                            <Button startIcon={<Print />} color="primary" onClick={() => window.open(`/#/print/transaction/${data.id}`, '_blank')}>Cetak</Button>
                        </Box>
                    )
                }}
            />
        </Box>
    )
}