import { configureStore } from '@reduxjs/toolkit'

import authReducer from './features/auth/data/auth.reducer'
import transactionReducer from './features/transaction/data/transaction.reducer'
import customerReducer from './features/customer/data/customer.reducer'
import packageReducer from './features/package/data/package.reducer'
import userReducer from './features/user/data/user.reducer'
import roleReducer from './features/role/data/role.reducer'

const authStorage = localStorage.getItem("authState")

export const store = configureStore({
    reducer: {
        auth: authReducer,
        transaction: transactionReducer,
        customer: customerReducer,
        package: packageReducer,
        user: userReducer,
        role: roleReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
    preloadedState: {
        auth: typeof (authStorage) === "string"
            ? {
                ...JSON.parse(authStorage),
                error: undefined
            }
            : undefined
    }
})

store.subscribe(() => {
    const serializedState = JSON.stringify(store.getState().auth)
    localStorage.setItem('authState', serializedState)
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch