import { Box, Button } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { InitialPackageEdit, InterfacePackageEdit } from "../data/package.interface";
import FormText from "../../../shared/form/FormText";
import FormMoney from "../../../shared/form/FormMoney";
import { useDispatch } from "react-redux";
import { createPackage, editPackage, getPackage } from "../data/package.api";
import alertSuccess from "../../../shared/alert/alertSuccess";

export default function PackageForm(props: { mode: "ADD" | "EDIT", data?: InterfacePackageEdit, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfacePackageEdit>(InitialPackageEdit)

    useEffect(() => {
        if (props.data !== undefined) {
            setValues(props.data)
        }
    }, [props.data])

    const handleChangeValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        let value = ["price"].includes(name) ? parseInt(e.target.value) : e.target.value
        setValues(last => ({
            ...last,
            [name]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createPackage(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editPackage(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            setValues(InitialPackageEdit)
            dispatch(getPackage())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <FormText label="Nama" name="name" value={values.name} required onChange={handleChangeValues} />
                <FormMoney label="Harga" name="price" value={values.price} required onChange={handleChangeValues} />
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button type="submit">Simpan</Button>
                </Box>
            </form>
        </Box>
    )
}