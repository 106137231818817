import { AddHome, ReceiptLong, Source, Summarize } from "@mui/icons-material";

import BookingPage from "./pages/BookingPage";
import TransactionPage from "./pages/TransactionPage";
import ReportPage from "./pages/ReportPage";
import CustomerPage from "./pages/master/CustomerPage";
import PackagePage from "./pages/master/PackagePage";
import UserPage from "./pages/master/UserPage";
import RolePage from "./pages/master/RolePage";

const routeList = [
    {
        name: "Booking",
        path: "/booking",
        icon: <AddHome />,
        component: <BookingPage />
    },
    {
        name: "Transaksi",
        path: "/transaction",
        icon: <ReceiptLong />,
        component: <TransactionPage />
    },
    {
        name: "Laporan",
        path: "/report",
        icon: <Summarize />,
        component: <ReportPage />
    },
    {
        name: "Master",
        path: "/master",
        icon: <Source />,
        child: [
            {
                name: "Pelanggan",
                path: "/customer",
                component: <CustomerPage />
            },
            {
                name: "Paket",
                path: "/package",
                component: <PackagePage />
            },
            {
                name: "User",
                path: "/user",
                component: <UserPage />
            },
            {
                name: "Role",
                path: "/role",
                component: <RolePage />
            }
        ]
    }
]

export default routeList 