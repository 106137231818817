import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import { getUser } from "../data/user.api";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceUserData } from "../data/user.interface";
import { Delete, Edit } from "@mui/icons-material";

export default function UserTable(props: {
    onEdit?: (data: InterfaceUserData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { userList } = useSelector((state: RootState) => state.user)

    useEffect(() => {
        dispatch(getUser())
    }, [dispatch])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Username",
                        key: "username"
                    },
                    {
                        name: "Role",
                        key: "role",
                        render: (data: InterfaceUserData) => (
                            <span>{data.role.name}</span>
                        )
                    }
                ]}
                dataList={userList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data: InterfaceUserData) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}