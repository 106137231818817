import { Box, Button, Container } from "@mui/material";
import TransactionBooking from "../features/transaction/components/TransactionBooking";
import TransactionCalendar from "../features/transaction/components/TransactionCalendar";
import { Add } from "@mui/icons-material";
import CustomModal from "../shared/CustomModal";
import { Fragment, useState } from "react";
import useAccess from "../hooks/useAccess";

export default function BookingPage() {

    const access = useAccess()

    const [modalAdd, setModalAdd] = useState(false)

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                title="Tambah Booking"
                onClose={() => setModalAdd(false)}
                component={<TransactionBooking onSubmit={() => setModalAdd(false)} />}
            />
            {access("CREATE") && (
                <Fragment>
                    <br />
                    <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                        <Button startIcon={<Add />} onClick={() => setModalAdd(true)}>Tambah Booking</Button>
                    </Box>
                </Fragment>
            )}
            <br />
            <TransactionCalendar />
            <br />
        </Container>
    )
}