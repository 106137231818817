import "./assets/style.scss"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, CircularProgress, CssBaseline, Modal } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from './store';
import Layout from './containers/components/Layout';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import routeList from './routeList';
import alertError from "./shared/alert/alertError";

import Login from "./features/auth/components/Login";
import TransactionPrint from "./features/transaction/components/TransactionPrint";

function App() {

  const navigate = useNavigate()

  const loginData = useSelector((state: RootState) => state.auth.loginData)

  const errorAuth = useSelector((state: RootState) => state.auth.error)
  const errorTransaction = useSelector((state: RootState) => state.transaction.error)
  const errorCustomer = useSelector((state: RootState) => state.customer.error)
  const errorPackage = useSelector((state: RootState) => state.package.error)
  const errorUser = useSelector((state: RootState) => state.user.error)
  const errorRole = useSelector((state: RootState) => state.role.error)

  const loadingAuth = useSelector((state: RootState) => state.auth.loading)
  const loadingTransaction = useSelector((state: RootState) => state.transaction.loading)
  const loadingCustomer = useSelector((state: RootState) => state.customer.loading)
  const loadingPackage = useSelector((state: RootState) => state.package.loading)
  const loadingUser = useSelector((state: RootState) => state.user.loading)
  const loadingRole = useSelector((state: RootState) => state.role.loading)

  const [pathAndComponentList, setPathAndComponentList] = useState<Array<{ path: string, component: ReactNode }>>([])

  useEffect(() => {
    if (errorAuth) alertError(errorAuth)
    if (errorTransaction) alertError(errorTransaction)
    if (errorCustomer) alertError(errorCustomer)
    if (errorPackage) alertError(errorPackage)
    if (errorUser) alertError(errorUser)
    if (errorRole) alertError(errorRole)
  }, [
    errorAuth,
    errorTransaction,
    errorCustomer,
    errorPackage,
    errorUser,
    errorRole
  ])

  useEffect(() => {
    if (loginData.id) {
      let pathAndComponentListNew: any[] = []
      routeList.map(route => {
        if (route.child) {
          route.child.map(child => {
            pathAndComponentListNew.push({ path: route.path + child.path, component: child.component })
            return null
          })
        }
        else if (route.path) {
          pathAndComponentListNew.push({ path: route.path, component: route.component })
        }
        return null
      })
      setPathAndComponentList(pathAndComponentListNew)
    }
    else {
      navigate('/login')
    }
  }, [loginData, navigate])

  const loading = (
    loadingAuth ||
    loadingTransaction ||
    loadingCustomer ||
    loadingPackage ||
    loadingUser ||
    loadingRole
  )

  return (
    <Fragment>
      <CssBaseline />
      <Modal open={loading}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100vh"}>
          <CircularProgress />
        </Box>
      </Modal>
      <Routes>
        {pathAndComponentList.map((item: any, i: number) => (
          <Route key={i} path={item.path} element={<Layout component={item.component} />} />
        ))}
        <Route path="/login" element={<Login />} />
        <Route path="/print/transaction/:id" element={<TransactionPrint />} />
        <Route path="/" element={<Navigate to='/booking' />} />
      </Routes>
    </Fragment>
  )
}

export default App;
