import { useSelector } from "react-redux"
import { RootState } from "../store"
import { useLocation } from "react-router-dom";

export default function useAccess(url?: string) {

    const location = useLocation()
    const pathname = location.pathname

    const { loginData } = useSelector((state: RootState) => state.auth)

    return function (access: "CREATE" | "EDIT" | "DELETE") {
        const accessDataFiltered = loginData.role.access.filter(accessItem => accessItem.url === (url || pathname))

        if (accessDataFiltered.length > 0) {
            const accessData = accessDataFiltered[0]
            if (accessData.url === (url || pathname) && accessData.access.includes(access)) {
                return true
            }
        }

        return false
    }
}