import { Box, Button, Container, Typography } from "@mui/material";
import PackageTable from "../../features/package/components/PackageTable";
import { Add } from "@mui/icons-material";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { InterfacePackageEdit } from "../../features/package/data/package.interface";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deletePackage, getPackage } from "../../features/package/data/package.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import PackageForm from "../../features/package/components/PackageForm";
import useAccess from "../../hooks/useAccess";

export default function PackagePage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [dataSelected, setDataSelected] = useState<InterfacePackageEdit | undefined>(undefined)

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deletePackage(id))
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getPackage())
                    } catch (error) { }
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                title="Tambah Paket"
                onClose={() => setModalAdd(false)}
                component={<PackageForm mode="ADD" onSubmit={() => setModalAdd(false)} />}
            />
            <CustomModal
                open={Boolean(dataSelected)}
                title="Edit Paket"
                onClose={() => setDataSelected(undefined)}
                component={<PackageForm mode="EDIT" data={dataSelected} onSubmit={() => setDataSelected(undefined)} />}
            />
            <br />
            <Typography variant="h3">Paket</Typography>
            {access("CREATE") && (
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button startIcon={<Add />} onClick={() => setModalAdd(true)}>Tambah</Button>
                </Box>
            )}
            <br />
            <PackageTable onEdit={access("EDIT") ? setDataSelected : undefined} onDelete={access("DELETE") ? handleDelete : undefined} />
        </Container>
    )
}