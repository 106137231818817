import { Container } from "@mui/material";
import TransactionReport from "../features/transaction/components/TransactionReport";

export default function ReportPage() {

    return (
        <Container>
            <br />
            <TransactionReport />
        </Container>
    )
}