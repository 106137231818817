export interface InterfaceTransactionData {
    id: number,
    ts_created: string,
    date_book: string,
    user_id: number,
    customer_id: number,
    customer: {
        name: string
    },
    package_list: Array<{
        price: number,
        quantity: number,
        package: {
            name: string
        }
    }>,
    pay_list: Array<{
        id: number,
        ts_created: string,
        value: number,
    }>,
    total: number,
    total_paid: number,
}

export interface InterfaceTransactionCreate {
    date_book: string,
    customer_id: number,
    package_list: Array<{
        package_id: number,
        quantity: number,
        price: number
    }>,
    pay_list: Array<{
        value: number,
    }>
}

export interface InterfaceTransactionEdit {
    id: number,
    date_book: string,
    customer_id: number
}

export const InitialTransactionData: InterfaceTransactionData = {
    id: 0,
    ts_created: "",
    date_book: "",
    user_id: 0,
    customer_id: 0,
    customer: {
        name: ""
    },
    package_list: [],
    pay_list: [],
    total: 0,
    total_paid: 0,
}

export const InitialTransactionCreate: InterfaceTransactionCreate = {
    date_book: "",
    customer_id: 0,
    package_list: [],
    pay_list: [{
        value: 0,
    }]
}

export const InitialTransactionEdit: InterfaceTransactionEdit = {
    id: 0,
    date_book: "",
    customer_id: 0
}