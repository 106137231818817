import { createSlice } from '@reduxjs/toolkit'
import { createTransaction, deleteTransaction, editTransaction, getTransactionByDate, getTransactionDetail, payTransaction } from './transaction.api'
import { InitialTransactionData, InterfaceTransactionData } from './transaction.interface'

export interface TransactionState {
    loading: boolean,
    error: any,
    transactionList: InterfaceTransactionData[],
    transactionDetail: InterfaceTransactionData,
    dateStart: string,
    dateEnd: string
}

const initialState: TransactionState = {
    loading: false,
    error: undefined,
    transactionList: [],
    transactionDetail: InitialTransactionData,
    dateStart: "",
    dateEnd: ""
}

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setDateStart: (state, action) => {
            state.dateStart = action.payload
        },
        setDateEnd: (state, action) => {
            state.dateEnd = action.payload
        }
    },
    extraReducers(builder) {

        builder.addCase(getTransactionByDate.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTransactionByDate.fulfilled, (state, action) => {
            state.loading = false
            state.transactionList = action.payload.data.data
        })
        builder.addCase(getTransactionByDate.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getTransactionDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTransactionDetail.fulfilled, (state, action) => {
            state.loading = false
            state.transactionDetail = action.payload.data.data
        })
        builder.addCase(getTransactionDetail.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createTransaction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createTransaction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createTransaction.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editTransaction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editTransaction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editTransaction.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteTransaction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteTransaction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteTransaction.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(payTransaction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(payTransaction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(payTransaction.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export const { setDateStart, setDateEnd } = transactionSlice.actions

export default transactionSlice.reducer