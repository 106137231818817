import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Chip, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDateEnd, setDateStart } from "../data/transaction.reducer";
import { RootState } from "../../../store";
import { getTransactionByDate } from "../data/transaction.api";
import CustomModal from "../../../shared/CustomModal";
import { InterfaceTransactionData } from "../data/transaction.interface";
import TransactionCalendarDetail from "./TransactionCalendarDetail";

export default function TransactionCalendar() {

    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
    const dayNames = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"]

    const dispatch = useDispatch<any>()

    const { dateStart, dateEnd, transactionList } = useSelector((state: RootState) => state.transaction)

    const [year, setYear] = useState(moment().year())
    const [month, setMonth] = useState(moment().month())
    const [dataSelectedList, setDataSelectedList] = useState<InterfaceTransactionData[]>([])

    const weekList = useMemo(() => {
        const generatedWeekList = []
        const firstDayOfMonth = moment({ year, month: month, day: 1 })
        const firstWeekNumber = firstDayOfMonth.isoWeek()
        for (let i = firstWeekNumber; i <= firstWeekNumber + 5; i++) {
            const weekNumber = i
            const startDate = moment().year(year).week(weekNumber).startOf('week')
            let dateList = []
            for (let j = 0; j < 7; j++) {
                dateList.push(startDate.clone().add(j, "day"))
            }
            generatedWeekList.push(dateList)
        }
        return generatedWeekList
    }, [year, month])

    useEffect(() => {
        dispatch(setDateStart(weekList[0][0].format("YYYY-MM-DD")))
        dispatch(setDateEnd(weekList[5][6].format("YYYY-MM-DD")))
    }, [dispatch, weekList])

    useEffect(() => {
        if (dateStart !== "" && dateEnd !== "") {
            dispatch(getTransactionByDate({ dateColumn: "date_book", dateStart, dateEnd }))
        }
    }, [dispatch, dateStart, dateEnd])

    const handleNext = () => {
        if (month < 11) {
            setMonth(month + 1)
        }
        else {
            setYear(year + 1)
            setMonth(0)
        }
    }

    const handlePrev = () => {
        if (month > 0) {
            setMonth(month - 1)
        }
        else {
            setYear(year - 1)
            setMonth(11)
        }
    }

    return (
        <Card variant="outlined">
            <CustomModal
                open={dataSelectedList.length > 0}
                title="Detail Transaksi"
                onClose={() => setDataSelectedList([])}
                component={<TransactionCalendarDetail dataList={dataSelectedList} />}
            />
            <CardHeader
                title={monthNames[month]}
                subheader={year}
                action={
                    <Box>
                        <IconButton onClick={handlePrev}><KeyboardArrowLeft /></IconButton>
                        <IconButton onClick={handleNext}><KeyboardArrowRight /></IconButton>
                    </Box>
                }
            />
            <CardContent>
                <Table sx={{ tableLayout: "fixed" }}>
                    <TableHead>
                        <TableRow>
                            {dayNames.map((day, i) => (
                                <TableCell key={i}>{day}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {weekList.map((dateList, i) => (
                            <TableRow key={i}>
                                {dateList.map((date, j) => {
                                    const onThisDate = transactionList.filter(transaction => moment(transaction.date_book).format("YYYY-MM-DD") === date.format("YYYY-MM-DD"))
                                    return (
                                        <TableCell key={j} sx={{ verticalAlign: "top", opacity: date.month() === month ? 1 : 0.3 }} onClick={() => setDataSelectedList(onThisDate)}>
                                            <Box border={1} padding={1} borderRadius={1} sx={{ cursor: "pointer", bgcolor: date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? "lightgray" : undefined }}>
                                                <Typography align="right" fontWeight="bold">{date.date()}</Typography>
                                                {onThisDate.map(transaction => {
                                                    return (
                                                        <Box borderBottom={1} marginTop={1} paddingBottom={1}>
                                                            <Typography>{transaction.id}</Typography>
                                                            <Typography>{transaction.customer.name}</Typography>
                                                            {transaction.total_paid < transaction.total ? <Chip size="small" color="error" label="Belum lunas" /> : <Chip size="small" label="Lunas" />}
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}