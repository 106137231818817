import { Box, Card, CardContent, Tab, Tabs } from "@mui/material";
import { InterfaceTransactionData } from "../data/transaction.interface";
import { useState } from "react";
import TransactionPrint from "./TransactionPrint";

export default function TransactionCalendarDetail(props: { dataList: InterfaceTransactionData[] }) {

    const [tabSelected, setTabSelected] = useState(0)

    return (
        <Box>
            <Tabs value={tabSelected} onChange={(_, value) => setTabSelected(value)} centered>
                {props.dataList.map((data, i) => (
                    <Tab key={i} label={data.id} value={i} />
                ))}
            </Tabs>
            <br />
            <Card variant="outlined">
                <CardContent>
                    <TransactionPrint data={props.dataList[tabSelected]} />
                </CardContent>
            </Card>
        </Box>
    )
}