import { createSlice } from '@reduxjs/toolkit'
import { createCustomer, deleteCustomer, editCustomer, getCustomer } from './customer.api'
import { InterfaceCustomerData } from './customer.interface'

export interface CustomerState {
    loading: boolean,
    error: any,
    customerList: InterfaceCustomerData[]
}

const initialState: CustomerState = {
    loading: false,
    error: undefined,
    customerList: []
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCustomer.fulfilled, (state, action) => {
            state.loading = false
            state.customerList = action.payload.data.data
        })
        builder.addCase(getCustomer.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createCustomer.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createCustomer.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editCustomer.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editCustomer.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteCustomer.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteCustomer.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export default customerSlice.reducer