import { createSlice } from '@reduxjs/toolkit'
import { createRole, deleteRole, editRole, getMenu, getRole } from './role.api'
import { InterfaceMenuData, InterfaceRoleData } from './role.interface'

export interface RoleState {
    loading: boolean,
    error: any,
    roleList: InterfaceRoleData[],
    menuList: InterfaceMenuData[]
}

const initialState: RoleState = {
    loading: false,
    error: undefined,
    roleList: [],
    menuList: []
}

export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getRole.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getRole.fulfilled, (state, action) => {
            state.loading = false
            state.roleList = action.payload.data.data
        })
        builder.addCase(getRole.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getMenu.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getMenu.fulfilled, (state, action) => {
            state.loading = false
            state.menuList = action.payload.data.data
        })
        builder.addCase(getMenu.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createRole.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createRole.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createRole.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editRole.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editRole.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editRole.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteRole.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteRole.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteRole.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export default roleSlice.reducer