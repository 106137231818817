import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRole } from "../data/role.api";
import { RootState } from "../../../store";

export default function RoleSelect(props: { value: number | null, onChange: (value: number | null) => void }) {

    const dispatch = useDispatch<any>()

    const { loading, roleList } = useSelector((state: RootState) => state.role)

    useEffect(() => {
        dispatch(getRole())
    }, [dispatch])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label="Role" />
                )
                : (
                    <Autocomplete
                        disablePortal
                        fullWidth
                        options={roleList}
                        getOptionLabel={options => options.name}
                        value={roleList.filter(role => role.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || null)}
                        renderInput={(params) => <TextField {...params} label="Role" />}
                    />
                )
            }
        </Box>
    )
}