import { createSlice } from '@reduxjs/toolkit'
import { createUser, deleteUser, editUser, getUser } from './user.api'
import { InterfaceUserData } from './user.interface'

export interface UserState {
    loading: boolean,
    error: any,
    userList: InterfaceUserData[]
}

const initialState: UserState = {
    loading: false,
    error: undefined,
    userList: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getUser.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.loading = false
            state.userList = action.payload.data.data
        })
        builder.addCase(getUser.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createUser.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createUser.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editUser.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editUser.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editUser.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteUser.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export default userSlice.reducer