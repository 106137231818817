export interface InterfaceRoleData {
    id: number,
    ts_created: string,
    name: string,
    access: Array<{
        url: string,
        access: string[]
    }>
}

export interface InterfaceMenuData {
    id: number,
    order: number,
    name: string
    url: string
    access: string[]
}

export interface InterfaceRoleCreate {
    name: string,
    access: Array<{
        url: string,
        access: string[]
    }>
}

export interface InterfaceRoleEdit {
    id: number,
    name: string,
    access: Array<{
        url: string,
        access: string[]
    }>
}

export const InitialRoleCreate: InterfaceRoleCreate = {
    name: "",
    access: []
}

export const InitialRoleEdit: InterfaceRoleEdit = {
    id: 0,
    name: "",
    access: []
}