import { createSlice } from '@reduxjs/toolkit'
import { createPackage, deletePackage, editPackage, getPackage } from './package.api'
import { InterfacePackageData } from './package.interface'

export interface PackageState {
    loading: boolean,
    error: any,
    packageList: InterfacePackageData[]
}

const initialState: PackageState = {
    loading: false,
    error: undefined,
    packageList: []
}

export const packageSlice = createSlice({
    name: 'package',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getPackage.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getPackage.fulfilled, (state, action) => {
            state.loading = false
            state.packageList = action.payload.data.data
        })
        builder.addCase(getPackage.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createPackage.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createPackage.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createPackage.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editPackage.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editPackage.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editPackage.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deletePackage.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deletePackage.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deletePackage.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export default packageSlice.reducer