import { Box, Button } from "@mui/material";
import { InterfaceTransactionData } from "../data/transaction.interface";
import TransactionPrint from "./TransactionPrint";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { payTransaction } from "../data/transaction.api";
import alertSuccess from "../../../shared/alert/alertSuccess";
import FormMoney from "../../../shared/form/FormMoney";
import { useEffect, useState } from "react";

export default function TransactionPay(props: { data?: InterfaceTransactionData, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const [value, setValue] = useState(0)

    useEffect(() => {
        if (props.data) {
            setValue(props.data?.total - props.data?.total_paid)
        }
    }, [props.data])

    const handlePay = () => {
        Swal.fire({
            icon: "warning",
            text: "Bayar pelunsasan"
        })
            .then(async result => {
                if (result.isConfirmed && props.data?.id) {
                    try {
                        await dispatch(payTransaction({ transaction_id: props.data?.id, value: value })).unwrap()
                        await alertSuccess("Berhasil mengubah data")
                        props.onSubmit()
                        window.open(`/#/print/transaction/${props.data.id}`, '_blank')
                    } catch (error) { }
                }
            })
    }

    if (!props.data) {
        return <></>
    }

    return (
        <Box>
            <TransactionPrint data={props.data} />
            <FormMoney max={props.data?.total - props.data?.total_paid} value={value} onChange={(e) => setValue(parseInt(e.target.value))} />
            <Button fullWidth sx={{ marginTop: 2 }} onClick={handlePay}>Bayar Pelunasan</Button>
        </Box>
    )
}