import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackage } from "../data/package.api";
import { RootState } from "../../../store";
import { InterfacePackageData } from "../data/package.interface";
import moneyParser from "../../../shared/moneyParser";

export default function PackageSelect(props: { value: number | null, onChange?: (value: InterfacePackageData | null) => void, readonly: boolean }) {

    const dispatch = useDispatch<any>()

    const { loading, packageList } = useSelector((state: RootState) => state.package)

    useEffect(() => {
        dispatch(getPackage())
    }, [dispatch])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label="Paket" />
                )
                : (
                    <Autocomplete
                        disablePortal
                        fullWidth
                        options={packageList}
                        getOptionLabel={option => `${option.name} - ${moneyParser(option.price)}`}
                        value={packageList.filter(packageValue => packageValue.id === props.value)[0]}
                        onChange={(_, value) => props.onChange && props.onChange(value)}
                        readOnly={props.readonly}
                        renderInput={(params) => <TextField {...params} label="paket" />}
                    />
                )
            }
        </Box>
    )
}