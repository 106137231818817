import { Container, Typography } from "@mui/material";
import TransactionTable from "../features/transaction/components/TransactionTable";
import { useDispatch, useSelector } from "react-redux";
import alertDelete from "../shared/alert/alertDelete";
import { deleteTransaction, getTransactionByDate } from "../features/transaction/data/transaction.api";
import alertSuccess from "../shared/alert/alertSuccess";
import { useState } from "react";
import { InterfaceTransactionData } from "../features/transaction/data/transaction.interface";
import CustomModal from "../shared/CustomModal";
import TransactionPay from "../features/transaction/components/TransactionPay";
import useAccess from "../hooks/useAccess";
import { RootState } from "../store";

export default function TransactionPage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const { dateStart, dateEnd } = useSelector((state: RootState) => state.transaction)

    const [dataSelected, setDataSelected] = useState<InterfaceTransactionData | undefined>(undefined)

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteTransaction(id))
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getTransactionByDate({ dateColumn: "date_book", dateStart, dateEnd }))
                    } catch (error) { }
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={Boolean(dataSelected)}
                title="Bayar Pelunasan"
                onClose={() => setDataSelected(undefined)}
                component={<TransactionPay data={dataSelected} onSubmit={() => { dispatch(getTransactionByDate({ dateColumn: "date_book", dateStart, dateEnd })); setDataSelected(undefined) }} />}
            />
            <br />
            <Typography variant="h3">Transaksi</Typography>
            <br />
            <TransactionTable onPay={access("EDIT") ? setDataSelected : undefined} onDelete={access("DELETE") ? handleDelete : undefined} />
        </Container>
    )
}