import { TextField } from "@mui/material";
import { ChangeEvent, ChangeEventHandler } from "react";
import moneyParser from "../moneyParser";

export default function FormMoney(props: {
    label?: string,
    name?: string,
    value: number,
    required?: boolean,
    disabled?: boolean,
    max?: number,
    readOnly?: boolean,
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}) {

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const valueNumber = e.target.value.replaceAll('.', '')
        if (props.max && parseInt(valueNumber) > props.max) {

        }
        else {
            props.onChange && props.onChange({
                ...e,
                target: {
                    ...e.target,
                    name: props.name || "",
                    value: valueNumber
                }
            })
        }
    }

    return (
        <TextField
            fullWidth
            label={props.label}
            value={moneyParser(props.value)}
            onChange={handleChange}
            required={props.required}
            disabled={props.disabled}
            InputProps={{ readOnly: props.readOnly }}
        />
    )
}