import { Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import FormDate from "../../../shared/form/FormDate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setDateEnd, setDateStart } from "../data/transaction.reducer";
import { Fragment, useEffect, useRef, useState } from "react";
import moment from "moment";
import { getTransactionByDate } from "../data/transaction.api";
import moneyParser from "../../../shared/moneyParser";
import { Print } from "@mui/icons-material";
import ReactToPrint from "react-to-print";

export default function TransactionReport() {

    const dispatch = useDispatch<any>()
    const printAreaRef = useRef<any>()

    const { dateStart, dateEnd, transactionList } = useSelector((state: RootState) => state.transaction)

    const [group, setGroup] = useState<"-" | "YYYY-MM-DD" | "YYYY-MM" | "YYYY" | string>("-")

    useEffect(() => {
        const now = moment()
        dispatch(setDateStart(now.clone().subtract(7, 'days').format("YYYY-MM-DD")))
        dispatch(setDateEnd(now.format("YYYY-MM-DD")))
    }, [dispatch])

    useEffect(() => {
        if (dateStart !== "" && dateEnd !== "") {
            dispatch(getTransactionByDate({ dateColumn: "ts_created", dateStart, dateEnd }))
        }
    }, [dispatch, dateStart, dateEnd])

    const getTransactionTotalTotal = (date: string) => {
        return transactionList.filter(transaction => moment(transaction.ts_created).format(group) === date).reduce((total, transaction) => total + transaction.package_list.reduce((t, pkg) => t + (pkg.price * pkg.quantity), 0), 0)
    }

    const getTransactionTotalDownPayment = (date: string) => {
        return 0
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <FormDate label="Tanggal mulai" value={dateStart} onChange={(e) => dispatch(setDateStart(e.target.value))} />
                </Grid>
                <Grid item xs={3}>
                    <FormDate label="Tanggal akhir" value={dateEnd} onChange={(e) => dispatch(setDateEnd(e.target.value))} />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <FormControl margin="dense" fullWidth>
                        <InputLabel>Grup berdasarkan</InputLabel>
                        <Select
                            value={group}
                            label="Grup berdasarkan"
                            onChange={(e) => setGroup(e.target.value)}
                        >
                            <MenuItem value={"-"}>-</MenuItem>
                            <MenuItem value={"YYYY-MM-DD"}>Per Hari</MenuItem>
                            <MenuItem value={"YYYY-MM"}>Per Bulan</MenuItem>
                            <MenuItem value={"YYYY"}>Per Tahun</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Card variant="outlined">
                <CardContent>
                    <Box ref={printAreaRef} padding={1}>
                        <Typography variant="h4">Laporan Transaksi</Typography>
                        <Typography>{moment(dateStart).format("YYYY-MM-DD")} - {moment(dateEnd).format("YYYY-MM-DD")}</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nomor Transaksi</TableCell>
                                    <TableCell>Tanggal Transaksi</TableCell>
                                    <TableCell>Tanggal Booking</TableCell>
                                    <TableCell>Pelanggan</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Jumlah DP</TableCell>
                                    <TableCell>Sisa Bayar</TableCell>
                                    <TableCell>Tanggal Pelunasan</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactionList.map((transaction, i) => {
                                    const transactionTotal = transaction.package_list.reduce((total: number, pkg) => total + (pkg.price * pkg.quantity), 0)
                                    return (
                                        <Fragment key={i}>
                                            <TableRow>
                                                <TableCell>{transaction.id}</TableCell>
                                                <TableCell>{moment(transaction.ts_created).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                                <TableCell>{moment(transaction.date_book).format("YYYY-MM-DD")}</TableCell>
                                                <TableCell>{transaction.customer.name}</TableCell>
                                                <TableCell align="right">{moneyParser(transactionTotal)}</TableCell>
                                            </TableRow>
                                            {transactionList[i + 1]?.id
                                                ? (
                                                    (moment(transaction.ts_created).format(group) !== moment(transactionList[i + 1].ts_created).format(group)) && (
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell sx={{ fontWeight: "bold" }}>{moment(transaction.ts_created).format(group)}</TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(getTransactionTotalTotal(moment(transaction.ts_created).format(group)))}</TableCell>
                                                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(getTransactionTotalDownPayment(moment(transaction.ts_created).format(group)))}</TableCell>
                                                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(getTransactionTotalTotal(moment(transaction.ts_created).format(group)))}</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                                : (
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell sx={{ fontWeight: "bold" }}>{moment(transaction.ts_created).format(group)}</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(getTransactionTotalTotal(moment(transaction.ts_created).format(group)))}</TableCell>
                                                        <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(getTransactionTotalDownPayment(moment(transaction.ts_created).format(group)))}</TableCell>
                                                        <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(getTransactionTotalTotal(moment(transaction.ts_created).format(group)))}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                )}
                                        </Fragment>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>
            <br />
            <Box display={"flex"} justifyContent={"flex-end"}>
                <ReactToPrint
                    trigger={() => {
                        return <Button startIcon={<Print />}>Cetak</Button>
                    }}
                    content={() => printAreaRef.current}
                />
            </Box>
        </Box>
    )
}