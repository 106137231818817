import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Content from './Content';
import Sidebar from './Sidebar';
import { ReactNode } from 'react';
import { Button } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { resetLoginData } from '../../features/auth/data/auth.reducer';
import ksl_sm from '../../assets/img/ksl_sm.png';

export default function Layout(props: { component: ReactNode }) {

    const dispatch = useDispatch<any>()

    const handleLogout = () => {
        dispatch(resetLoginData())
        window.location.replace("/")
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Box display={"flex"} flexGrow={1} justifyContent={"space-between"}>
                        <Box>
                            <img src={ksl_sm} alt='head' height={38} />
                        </Box>
                        <Button color='primary' variant='outlined' startIcon={<Logout />} onClick={handleLogout}>Logout</Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Sidebar />
            <Content component={props.component} />
        </Box>
    )
}