import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import useAccess from "../../../hooks/useAccess";
import CustomModal from "../../../shared/CustomModal";
import CustomerForm from "../../customer/components/CustomerForm";
import CustomerTable from "../../customer/components/CustomerTable";
import { InterfaceCustomerData } from "../../customer/data/customer.interface";

export default function TransactionBookingCustomer(props: {
    onSelect?: (data: InterfaceCustomerData) => void,
    selectedId?: number,
}) {

    const access = useAccess("/master/customer")

    const [modalAdd, setModalAdd] = useState<boolean>(false)

    return (
        <Box>
            <CustomModal
                open={modalAdd}
                title="Tambah Pelanggan"
                onClose={() => setModalAdd(false)}
                component={<CustomerForm mode="ADD" onSubmit={() => setModalAdd(false)} />}
            />
            {access("CREATE") && (
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button startIcon={<Add />} onClick={() => setModalAdd(true)}>Tambah</Button>
                </Box>
            )}
            <br />
            <CustomerTable {...props} />
        </Box>
    )
}