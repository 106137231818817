import { Box, Button, Card, CardContent, CardHeader, FormHelperText, Grid, IconButton, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { InitialTransactionCreate, InterfaceTransactionCreate } from "../data/transaction.interface";
import FormMoney from "../../../shared/form/FormMoney";
import PackageSelect from "../../package/components/PackageSelect";
import { InterfacePackageData } from "../../package/data/package.interface";
import { Add, Close } from "@mui/icons-material";
import FormNumber from "../../../shared/form/FormNumber";
import moneyParser from "../../../shared/moneyParser";
import FormDate from "../../../shared/form/FormDate";
import { useDispatch, useSelector } from "react-redux";
import { createTransaction, getTransactionByDate } from "../data/transaction.api";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { RootState } from "../../../store";
import TransactionBookingCustomer from "./TransactionBookingCustomer";
import { InterfaceCustomerData } from "../../customer/data/customer.interface";

export default function TransactionBooking(props: { onSubmit?: () => void }) {

    const dispatch = useDispatch<any>()

    const { dateStart, dateEnd } = useSelector((state: RootState) => state.transaction)

    const [values, setValues] = useState<InterfaceTransactionCreate>(InitialTransactionCreate)
    const [packageValue, setPackageValue] = useState<InterfacePackageData | null>(null)
    const [quantityValue, setQuantityValue] = useState(1)
    const [customerSelected, setCustomerSelected] = useState<InterfaceCustomerData | null>(null)

    const handleChangeValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        let value = ["value_down_payment", "room_id"].includes(name) ? parseInt(e.target.value) : e.target.value
        setValues(last => ({
            ...last,
            [name]: value
        }))
    }

    const handleAddPackage = () => {
        if (packageValue?.id) {
            setValues(last => ({
                ...last,
                package_list: [...last.package_list, {
                    package_id: packageValue.id,
                    price: packageValue.price,
                    quantity: quantityValue
                }]
            }))
            setPackageValue(null)
            setQuantityValue(1)
        }
    }

    const handleChangeQuantityPackage = (index: number, value: number) => {
        setValues(last => ({
            ...last,
            package_list: last.package_list.map((p, i) => {
                if (i === index) {
                    return {
                        ...p,
                        quantity: value
                    }
                }
                return p
            })
        }))
    }

    const handleDeletePackage = (index: number) => {
        setValues(last => ({
            ...last,
            package_list: last.package_list.filter((_, i) => i !== index)
        }))
    }

    const total = values.package_list.reduce((total: number, p) => total + (p.price * p.quantity), 0)

    const handleSubmit = async () => {
        try {
            const data: InterfaceTransactionCreate = {
                ...values,
                customer_id: customerSelected?.id || 0
            }
            const response = await dispatch(createTransaction(data)).unwrap()
            await alertSuccess("Berhasil booking")
            setValues(InitialTransactionCreate)
            dispatch(getTransactionByDate({ dateColumn: "date_book", dateStart, dateEnd }))
            window.open(`/#/print/transaction/${response.data.data.id}`, '_blank')
            props.onSubmit && props.onSubmit()
        } catch (error) { }
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <FormDate label="Tanggal Booking" name="date_book" value={values.date_book} onChange={handleChangeValues} required />
                <Card variant="outlined" sx={{ marginTop: 1 }}>
                    <CardHeader subheader={`Pelanggan terpilih: ${customerSelected?.name || '-'}`} />
                    <CardContent>
                        <TransactionBookingCustomer onSelect={setCustomerSelected} selectedId={customerSelected?.id} />
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{ marginTop: 1 }}>
                    <CardContent>
                        {values.package_list.map((pkg, i) => (
                            <Grid key={i} container spacing={2}>
                                <Grid item xs={4}>
                                    <PackageSelect value={pkg.package_id} readonly />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormMoney label="Harga" value={pkg.price} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormNumber label="Quantity" value={pkg.quantity} onChange={(e) => handleChangeQuantityPackage(i, parseInt(e.target.value))} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
                                        <Typography>{moneyParser(pkg.price * pkg.quantity)}</Typography>
                                        <IconButton onClick={() => handleDeletePackage(i)}><Close /></IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container spacing={2} marginTop={1}>
                            <Grid item xs={9}>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
                                    <Typography fontWeight={"bold"}>Total :</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
                                    <Typography marginRight={"52px"} fontWeight={"bold"}>{moneyParser(total)}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1}>
                            <Grid item xs={7}>
                                <PackageSelect value={packageValue?.id || null} onChange={value => setPackageValue(value)} readonly={false} />
                            </Grid>
                            <Grid item xs={2}>
                                <FormNumber label="Quantity" value={quantityValue} onChange={(e) => setQuantityValue(parseInt(e.target.value))} />
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullWidth startIcon={<Add />} sx={{ marginTop: "9px" }} disabled={packageValue === null || quantityValue === 0} onClick={handleAddPackage}>Tambah</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {values.package_list.length === 0 && (
                    <FormHelperText sx={{ color: "orange" }}>Pilih minimal satu paket</FormHelperText>
                )}
                <Card variant="outlined" sx={{ marginTop: 1 }}>
                    <CardHeader subheader="Pembayaran" />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormMoney max={total} value={values.pay_list[0].value || 0} onChange={(e) => setValues(last => ({ ...last, pay_list: [{ value: parseInt(e.target.value) }] }))} />
                            </Grid>
                            <Grid item xs={6} display={"flex"} alignItems={"center"}>
                                <Typography>Hutang</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormMoney readOnly value={total - (values.pay_list[0].value || 0)} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <Button sx={{ marginTop: 1 }} disabled={values.package_list.length === 0 || customerSelected === null} onClick={handleSubmit}>Submit</Button>
                </Box>
            </CardContent>
        </Card>
    )
}